<template>
  <div v-if="isLoaded">
    <h1 class="title is-2">Kérdőív | {{ $store.state.survey.name }} </h1>
    <h5 class="title is-6 is-italic">{{ $store.state.survey.extraData }} </h5>
    <div v-if="attentionDemandingQuestions.length > 0"
         class="has-background-danger has-text-white mb-2 px-3 py-2">
      <div class="is-size-4 has-text-weight-bold">
        A kérdőívben az alábbi figyelmet igénylő kérdések vannak:
      </div>
      <ul>
        <li v-for="(question, index) in attentionDemandingQuestions"
            :key="`attentionDemandingQuestions_${index}`">
          Kérdésszám: {{ question.number }}
        </li>
      </ul>
    </div>
    <section class="mb-6">
      <QuestionBlock v-for="questionBlock in $store.state.survey.questionBlocks"
                     :key="`questionBlock_${$route.params.survey_ID}_${questionBlock.id}`"
                     class="mb-1"
                     :id="questionBlock.id"
                     :name="questionBlock.name"
                     :questions="questionBlock.questions"
                     :extra-question="questionBlock.extra_question ? questionBlock.extra_question : null"/>
    </section>
    <SurveyFooterMenu/>
  </div>
  <div v-else
       class="loading-container">
    <Loading :is-active="true"/>
  </div>
</template>

<script>

import QuestionBlock from '@/components/Survey/QuestionBlock'
import SurveyFooterMenu from '@/components/Survey/SurveyFooterMenu'
import Loading from '@/components/Loading'
import question from '../../components/Survey/Question.vue'

export default {
  name: 'InspectionSurvey',
  components: { SurveyFooterMenu, QuestionBlock, Loading },
  data () {
    return {
      isLoaded: false,
    }
  },
  computed: {
    question () {
      return question
    },
    attentionDemandingQuestions () {
      return this.$store.state.survey.questionBlocks
        .map(questionBlock => questionBlock.questions
          .filter(question => question.question_type === 'silent_killer_question' && question.answer === 0),
        )
        .flat()
    },
  },
  watch: {
    '$route.params.surveyID': {
      handler: async function () {
        this.isLoaded = false
        await this.$store.dispatch('fetchSurvey', this.$route.params.surveyID)
        this.isLoaded = true
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>

<style scoped>
.loading-container {
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 400px;
  min-height: 400px;
}
</style>
