<template>
  <div class="mt-3 question"
       :class="`q-${status}`">
    <div class="q-container is-flex has-addons-fullwidth">
      <div class="is-width-100-percent">
        <div v-if="questionType === 'silent_killer_question'
                    && answer === 0
                    && Number($store.state.inspection.selected.status_id) === 5"
             class="has-background-danger has-text-white p-3 align-center">
          <span class="is-size-4 has-text-weight-bold">Figyelmet igénylő kérdés-válasz!</span>
          <b-button v-if="$store.getters.isAdmin"
                    class="is-pulled-right"
                    type="is-warning">Minden válasz nemlegessé tétele (TEST)
          </b-button>
        </div>
        <h5 class="title is-6 q-title">
          <b>{{ number }}. </b>
          <b-tooltip label="Válasz bizottság általi módosítása"
                     position="is-bottom">
            <i v-if="Number($store.state.inspection.selected.status_id) === 5 && $store.getters.minUserLevelGroupLeader"
               @click="editQuestion"
               class="fas fa-edit pointer-cursor"></i>
          </b-tooltip>
          <br>
          <span v-html="text"></span>
        </h5>
        <div v-if="answerOptions.length > 0"
             class="q-answer-options is-flex is-flex-wrap-wrap is-justify-content-center">
          <b-field>
            <!--            TODO: editable megadása az ellenőrzés státusza alapján-->
            <AnswerCheckbox v-if="answerOptions.includes(1)"
                            @click="setAnswer(1)"
                            :label="getAnswerText(1)"
                            :editable="Number($store.state.inspection.selected.status_id) === 3"
                            :is-selected="Number(answerVal) === 1"
                            :is-loading="isAnswerLoading"/>
            <AnswerCheckbox v-if="answerOptions.includes(0)"
                            @click="setAnswer(0)"
                            :label="getAnswerText(0)"
                            :editable="Number($store.state.inspection.selected.status_id) === 3"
                            :is-selected="Number(answerVal) === 0"
                            :is-loading="isAnswerLoading"/>
            <AnswerCheckbox v-if="answerOptions.includes(2)"
                            @click="setAnswer(2)"
                            :label="getAnswerText(2)"
                            :editable="Number($store.state.inspection.selected.status_id) === 3"
                            :is-selected="Number(answerVal) === 2"
                            :is-loading="isAnswerLoading"/>
          </b-field>
        </div>
        <div class="q-extensions">
          <span v-if="answerOptions.length > 0">
            <div v-if="isAnswerModified"
                 class="q-committee-comment">
              <h6 class="title is-6 mb-0">
                <span>Bizottsági módosítás</span>
                <b-button type="is-ghost"
                          class="has-text-danger text-button-height-modifier pb-3"
                          @click="$store.dispatch('openModal', {
                               modalName: 'ConfirmationModal',
                               data: {
                                 text: 'Biztos törli a bizottsági módosításokat?',
                                 confirmFunction: () => {
                                   $store.dispatch('restoreAnswer', {
                                     surveyID: $route.params.surveyID,
                                     questionID: id,
                                   })
                                 },
                               },
                             })">
                  Törlés
                </b-button>
              </h6>
              <div class="m-0 p-0 border-bottom">
                <AnswerCheckbox :label="`Eredeti válasz: ${getAnswerText(originalAnswer)}`"
                                :editable="false"
                                :is-selected="false"
                                class="p-0 m-0"/>
              </div>
              <span class="is-block has-text-weight-medium">Megjegyzés</span>
              <span>{{ committeeComment }}</span>
            </div>
            <div v-if="comment"
                 class="q-comment">
              <h6 class="title is-6">Megjegyzés</h6>
              {{ comment }}
            </div>
            <div v-if="documentRegister !== null"
                 class="q-documents">
              <h6 class="title is-6">Dokumentum jegyzék</h6>
              {{ documentRegister.name }}
            </div>
            <div class="q-actions is-flex is-justify-content-center">
              <b-button v-if="Number($store.state.inspection.selected.status_id) === 3"
                        type="is-primary"
                        class="mr-2"
                        @click="addComment">
                Megjegyzés hozzáadása
              </b-button>
              <b-button type="is-primary"
                        @click="openDocumentRegister">
                Dokumentumjegyzék
              </b-button>
            </div>
          </span>
          <div v-show="referenceTxt"
               class="q-reference">
            <div>
              <i>Hivatkozás: {{ referenceTxt }}</i>
            </div>
          </div>
        </div>
      </div>
      <div v-if="attachedDocuments && attachedDocuments.length > 0"
           class="q-attached-documents-wrapper is-relative has-background-success has-text-white">
        <ul class="px-2">
          <li v-for="document in attachedDocuments"
              @click="downloadFile(document.id)"
              :key="`attachedDocument_${id}_${document.id}`"
              class="has-text-centered pointer-cursor">
            <b-icon :icon="getFileIconClass(document.ext)"
                    pack="far"
                    size="is-large"/>
            <div :title="document.name"
                 class="q-filename">{{ document.name }}
            </div>
          </li>
        </ul>
        <div @click="downloadQuestionAllFile"
             class="q-documents-downloader">Össz Letöltés
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAnswerText, getFileIconClass } from '@/utils/utils'
import AnswerCheckbox from '@/components/Survey/AnswerCheckbox'

export default {
  name: 'Question',
  components: { AnswerCheckbox },
  props: {
    id: {
      required: true,
    },
    number: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    comment: {
      type: String,
    },
    committeeComment: {
      type: String,
    },
    documentRegister: {
      required: true,
    },
    attachedDocuments: {
      type: Array,
      required: true,
    },
    answer: {
      type: Number,
      required: true,
    },
    originalAnswer: {
      type: Number,
      required: true,
    },
    isAnswerModified: {
      type: Boolean,
      required: true,
    },
    referenceTxt: {
      type: String,
      default: '',
    },
    isAnswerAcceptable: {
      required: true,
    },
    answerOptions: {
      type: Array,
      required: true,
    },
    inverse: {
      type: Boolean,
      required: true,
    },
    questionType: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      answerVal: this.answer,
      isAnswerLoading: false,
    }
  },
  methods: {
    getFileIconClass,
    getAnswerText,
    editQuestion () {
      this.$store.dispatch('openModal', {
        modalName: 'SurveyModifyQuestionOriginalAnswer',
        data: {
          inspectionID: this.$route.params.inspectionID,
          surveyID: this.$route.params.surveyID,
          question: this.$props,
        },
      })
    },
    addComment () {
      this.$store.dispatch('openModal', {
        modalName: 'SurveyCommentModal',
        data: {
          questionID: this.id,
          currentComment: this.comment,
        },
      })
    },
    openDocumentRegister () {
      this.$store.dispatch('openModal', {
        modalName: 'DocumentRegisterModal',
        data: {
          questionID: this.id,
          documentRegister: this.documentRegister,
          userAttachedDocuments: this.attachedDocuments,
          surveyID: this.$route.params.surveyID,
        },
      })
    },
    async setAnswer (newAnswer) {
      this.isAnswerLoading = true
      const answerResponse = await this.$store.dispatch('setAnswer', {
        surveyID: this.$route.params.surveyID,
        questionID: this.id,
        answer: newAnswer,
      })
      if (answerResponse) {
      }
      this.isAnswerLoading = false
    },
    async downloadFile (documentID) {
      await this.$store.dispatch('downloadOnlineInspectionFile', {
        documentID: documentID,
        isDownload: 'download',
      })
    },
    async downloadQuestionAllFile () {
      await this.$store.dispatch('downloadOnlineInspectionQuestionAllFile', {
        surveyID: this.$route.params.surveyID,
        questionID: this.id,
        isDownload: 'download',
      })
    },
  },
  computed: {
    status () {
      let status = 'default'
      if (this.answer !== -1 && this.isAnswerAcceptable !== null) {
        status = this.isAnswerAcceptable ? 'success' : 'danger'
        if (
          (this.answer === 1 && this.isAnswerAcceptable && this.inverse) ||
          (this.answer === 0 && this.isAnswerAcceptable && !this.inverse)) {
          status = 'semi-success'
        }
      }
      return status
    },
  },
  watch: {
    answer: async function (newVal, prevVal) {
      this.answerVal = newVal
      console.log(`TODO SAVE ANSWER newVal ${newVal} | prevVal ${prevVal}`)
    },
  },

}
</script>

<style lang="scss"
       scoped>
@import "../../assets/css/_custom-variables.scss";

.question {
  background: $survey-default;

  .q-filename {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .q-attached-documents-wrapper {
    width: 150px;

    .q-documents-downloader {
      bottom: 0;
      position: absolute;
      width: 100%;
      text-align: center;
      background: $primary;
      color: $white;
      padding: 0.3rem 0;
      cursor: pointer;

      &:hover {
        opacity: .85;
      }
    }
  }

  .q-title {
    padding: .5rem;
    margin-bottom: 0;
    //color: $white;
    font-weight: 400;
    background: $survey-default-header;
  }

  .q-container {
  }

  .q-answer-options {
    padding: 1rem;
  }

  .q-extensions {
    background: rgba($main-grey, .2);
    //background: $main-grey;
    //opacity: .8;
    padding-top: .1rem;

    div {
      padding: .5rem;
    }

    .q-comment, .q-documents {
      margin: .5rem;
      background: rgba($main-grey, .3);
    }

    .q-committee-comment {
      margin: .5rem;
      background: rgba($yellow, .3);
    }

    .q-reference {
      div {
        padding-top: 0;
        border-top: 1px solid grey;
        opacity: .8;
      }
    }
  }

  &.q-danger {
    background: $survey-danger;

    .q-title {
      background: $survey-danger-header;
      color: $white;
    }
  }

  &.q-success {
    background: $survey-success;

    .q-title {
      background: $survey-success-header;
      color: $white;
    }
  }

  &.q-semi-success {
    background: $survey-semi-success;

    .q-title {
      background: $survey-semi-success-header;
      color: $white;
    }
  }
}
</style>
