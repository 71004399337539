<template>
  <div class="survey-footer-menu-container has-text-weight-normal">
    <span>Kérdések száma: {{ getSurveyStatistic.questionsNumber }} | </span>
    <span>Igen válaszok száma: {{ getSurveyStatistic.yesAnswersNumber }} | </span>
    <span>Nem válaszok száma: {{ getSurveyStatistic.noAnswersNumber }} | </span>
    <span>N/É válaszok száma: {{ getSurveyStatistic.notApplicableAnswersNumber }} | </span>
    <span>
<!--      TODO: mindent kitölt action-->
      <b-button v-if="$store.getters.minUserLevelAdmin && isDev"
                @click="fillSurvey"
                icon-left="check-square"
                icon-pack="far"
                type="is-ghost"
                class="has-text-white text-button-height-modifier">Mindent kitölt</b-button>
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SurveyFooterMenu',
  data () {
    return {
      isDev: process.env.NODE_ENV === 'development',
    }
  },
  methods: {
    fillSurvey () {
      this.$store.dispatch('fillSurvey', { surveyID: this.$route.params.surveyID })
    },
  },
  computed: {
    ...mapGetters(['getSurveyStatistic']),
  },
}
</script>

<style lang="scss"
       scoped>
@import "../../assets/css/_custom-variables.scss";

.survey-footer-menu-container {
  position: fixed;
  bottom: 0;
  left: 0;
  background: $green;
  color: $white;
  width: 100%;
  text-align: center;
  padding: .5rem 0;
  font-weight: 500;
  z-index: 1;
}
</style>
