<template>
  <b-collapse :open="true"
              :aria-id="`questionBlock_${id}`">
    <template #trigger="props">
      <div class="has-background-grey-light p-2 title is-5"
           :aria-controls="`questionBlock_${id}`"
           :aria-expanded="props.open">
        <b-icon v-if="questionBlockStatus === 'success'"
                class="has-text-success mr-2"
                icon="check"/>
        <b-icon v-if="questionBlockStatus === 'danger'"
                class="has-text-danger mr-2"
                icon="exclamation"/>
        <span>{{ name }}</span>
      </div>
    </template>
    <div>
      <div v-if="extraQuestion">
        <div class="columns is-multiline is-gapless mt-1 p-2 has-background-grey-lighter">
          <strong class="column is-6">{{ extraQuestion.text_question }} </strong>
          <div class="column is-6">
            {{ extraQuestion.text_answer }}
            <b-button class="is-pulled-right text-button-height-modifier has-text-grey"
                      icon-left="edit"
                      @click="openExtraQuestionEditModal"
                      type="is-ghost">
              Módosít
            </b-button>
          </div>
          <strong class="column is-6">{{ extraQuestion.select_question }} </strong>
          <div class="column is-6">{{ extraQuestion.select_answer }}</div>
        </div>
      </div>
      <Question v-for="question in questions"
                :key="question.number"
                :id="question.id"
                :number="question.number"
                :text="question.text"
                :comment="question.comment"
                :committee-comment="question.committee_comment"
                :document-register="question.document_register"
                :attached-documents="question.attached_documents"
                :answer="question.answer"
                :original-answer="question.original_answer"
                :is-answer-modified="question.is_answer_modified"
                :answer-options="question.answer_options"
                :is-answer-acceptable="question.is_answer_acceptable"
                :reference-txt="question.reference_txt"
                :question-type="question.question_type"
                :inverse="question.inverse"/>
    </div>
  </b-collapse>
</template>

<script>
import Question from '@/components/Survey/Question'

export default {
  name: 'QuestionBlock',
  components: { Question },
  props: {
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    questions: {
      type: Array,
      required: true,
    },
    extraQuestion: {
      required: true,
    },
  },
  data () {
    return {
      testExtraQuestion: {
        id: 12341,
        text_question: 'Ez egy szabad szöveges kérdés',
        text_answer: 'Ez egy válasz',
        select_question: 'Ez egy kérdés a szelektálható válaszhoz',
        select_answer: 'Válasz',
        answer_options: [
          { txt: 'Válasz' },
          { txt: 'Válasz 2' },
        ],
      },
    }
  },
  methods: {
    openExtraQuestionEditModal () {
      this.$store.dispatch('openModal', {
        modalName: 'SurveyExtraQuestionModal',
        data: { extraQuestion: this.extraQuestion },
      })
    },
  },
  computed: {
    questionBlockStatus () {
      let status = 'default'
      let acceptableAnswerCount = 0
      let extraQuestionAccepted = true

      if (this.extraQuestion) {
        extraQuestionAccepted = this.extraQuestion.is_anwser_acceptable
      }

      for (const question of this.questions) {
        if (question.is_answer_acceptable !== null) {
          if (!question.is_answer_acceptable) {
            status = 'danger'
            break
          }
          if (question.is_answer_acceptable) {
            acceptableAnswerCount++
          }
        }
      }

      if (acceptableAnswerCount === this.questions.length) {
        if (extraQuestionAccepted) {
          status = 'success'
        } else {
          status = 'danger'
        }
      }
      return status
    },
  },
}
</script>

<style scoped>

</style>
